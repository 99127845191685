import axios from "axios";
// import ApiConfig from "./apiconfig";
import ApiConfig from "./Apiconfig";
import toast from "react-hot-toast";

export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token:sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    // if (error.response.status === 440) {
    //   window.location.href = "/";
    // }
    return false;
  }
};

export const postDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
if(error?.response?.data?.responseMessage){
 toast.error(error?.response?.data?.responseMessage);
}
else{
  toast.error("Something went wrong")
}
   
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};
 
export const uploadFileDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: data,
    });

    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: _id,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const getDataHandlerAPI = async (endPoint, dataSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: dataSend,
    });
    if (res.data.responseCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${ApiConfig[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      // params: _id,
    });
    if (res) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return error?.response?.data?.responseMessage;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${ApiConfig[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const putAPIHandler = async (endPoint, dataToSend, id) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: id,
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data?.result;
    } else {
      toast.success(res.responseMessage);
    }
  } catch (error) {
    if (error.response.responseCode === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });

    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const postDataHandlerWithToken = async (endPoint) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const postDataHandlerWithoutToken = async (endPoint) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const postDataHandlerWithTokenData = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const putDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],

      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const putDataHandlerWithToken = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      return res.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const patchDataHandler = async (endPoint, data) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      data: data,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      // toast.error(res.data.responseMessage);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    console.log(error);
  }
};

export const deleteDataAPIHandlerForm = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: _id,
    });
    if (res.data.responseCode === 200) {
      // toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};
