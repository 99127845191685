// const url = "https://node-walkandearn.mobiloitte.io"; // staging
const url = "https://node.Fit-hun.com/";
// const url = "http://172.16.6.128:2000";
// const botSok = "https://pypune-arbitragebot.mobiloitte.io"; // stagingy

// const url = "https://no.mbaacademy.asia";// client live url
// const botSok = "https://python.mbaacademy.asia"; // client live url

// const url = "http://172.16.2.14:3053" // suraj sir local

// const url ="http://172.16.0.128:3053"
// const url = "http://13.126.109.55:3053"; // live
// const url = "https://node.mbaacademy.asia"; // liveurl
// const botSok = "http://13.126.109.55:5001"; // liveurl

let triangularArbitrage = `${url}/api/v1/arbitrage`;
let wallet = `${url}/api/v1/exchangeWallet`;
let notification = `${url}/api/v1/notification`;

const ApiConfig = {
  viewStaticContent: `${url}/api/static_content/viewStaticContent`,
  contactUs: `${url}/api/contact/contactUs`,
};

// const ApiConfig = {
//   botSok,
//   login: `${url}/api/v1/user/login`,
//   signup: `${url}/api/v1/user/register`,
//   verify: `${url}/api/v1/user/verify`,
//   forgot: `${url}/api/v1/user/forgot`,
//   changePassword: `${url}/api/v1/user/changePassword`,
//   resend: `${url}/api/v1/user/resend`,
//   profile: `${url}/api/v1/user/profile`,
//   editProfile: `${url}/api/v1/user/editProfile`,
//   uploadImage: `${url}/api/v1/upload/uploadImage`,
//   kycApply: `${url}/api/v1/user/kycApply`,
//   viewKyc: `${url}/api/v1/user/viewKyc`,
//   planlist: `${url}/api/v1/plan/list`,
//   updatePassword: `${url}/api/v1/user/updatePassword`,
//   myWallets: `${url}/api/v1/user/myWallets`,
//   deposit: `${url}/api/v1/user/deposit`,
//   history: `${url}/api/v1/user/history`,
//   withdraw: `${url}/api/v1/user/withdraw`,
//   genOtp: `${url}/api/v1/user/genOtp`,
//   fsfs: `${url}/api/v1/user/buy/`,
//   activePlan: `${url}/api/v1/user/activePlan`,
//   updateSniperTradeCount: `${url}/api/v1/user/updateSniperTradeCount`,
//   checkPlan:`${url}/api/v1/user/checkPlan`,
//   checkSniperPlan:`${url}/api/v1/user/checkSniperPlan`,
//   getUserWalletList:`${url}/api/v1/user/getUserWalletList`,

//     // Admin
//     viewUserActivePlan:`${url}/api/v1/user/viewUserActivePlan`,

//   //BotSetting
//   multiStop:`${botSok}/stop`,
//   start:`${botSok}/start`,
//   checkPrivate_key:`${botSok}/checkPrivate_key`,
//   tokenDeatils:`${botSok}/tokenDeatils`,

//   //snipertransaction
//   activeWallet:`${botSok}/activeWallet`,
//   boutghtcoins:`${botSok}/boutghtcoins`,
//   sellcoins:`${botSok}/sellcoins`,
//   foundcoins:`${botSok}/foundcoins`,
//   keepholding:`${botSok}/keepholding`,
//   miniAudits:`${botSok}/miniAudits`,
//   tradeInfo:`${botSok}/tradeInfo`,

//   // Affialiate
//   affiliate: `${url}/api/v1/affiliate/affiliate`,
//   affiliateList: `${url}/api/v1/affiliate/affiliateList`,
//   getUserDetails: `${url}/api/v1/affiliate/getUserDetails`,
//   staticContent: `${url}/api/v1/static/static`,
//   getTotalAffiliatePrice: `${url}/api/v1/affiliate/getTotalAffiliatePrice`,

//   //get salary income
//   getSalaryIncome:`${url}/api/v1/affiliate/getSalaryIncome`,

//   //prelaunching offer
//   getPreLaunchingOffer:`${url}/api/v1/affiliate/getPreLaunchingOffer`,

//   //getRewardIncome
//   getRewardIncome:`${url}/api/v1/affiliate/getRewardIncome`,

//   //notification
//   listNotification: `${notification}/listNotification`,
//   readNotification: `${notification}/readNotification`,
//   clearNotification: `${notification}/clearNotification`,

//   //Triangular

//   filterProfitPathsTriangular: `${triangularArbitrage}/filterProfitPaths`,
//   filterProfitPathsTriangularGo: `${triangularArbitrage}/filterProfitPaths`,
//   tradeProfitPathsTriangular: `${triangularArbitrage}/tradeProfitPaths`,
//   listPlacedTradeTriangular: `${triangularArbitrage}/listPlacedTrade`,
//   viewPlacedTradeTriangular: `${triangularArbitrage}/viewPlacedTrade`,
//   activeBlockvPlacedTradeTriangular: `${triangularArbitrage}/activeBlockPlacedTrade`,
//   deletePlacedTradeTriangular: `${triangularArbitrage}/deletePlacedTrade`,
//   cancelledOrderTriangular: `${triangularArbitrage}/cancelledOrder`,
//   autoTradeOnOffTriangular: `${triangularArbitrage}/autoTradeOnOff`,

//   //wallet
//   connectedExchangeList: `${wallet}/connectedExchangeList`,
//   listExchange: `${wallet}/listExchange`,
//   serverIPAddress: `${wallet}/serverIPAddress`,
//   connectExchange: `${wallet}/connectExchange`,
//   exchangeCoins: `${wallet}/exchangeCoins`,
//   removeConnectedExchange: `${wallet}/removeConnectedExchange`,
//   asks_bids_prices: `${wallet}/asks_bids_prices`,
//   mexcPairList: `${wallet}/mexcPairList`,
//   generateAddress: `${wallet}/generateAddress`,
//   getWithdrawAddress: `${wallet}/getWithdrawAddress`,
//   exchangeBalance: `${wallet}/exchangeBalance`,
//   withdrawHistory: `${wallet}/withdrawHistoryy`,
//   Dashboard: `${wallet}/Dashboard`,
//   withdrawDepositeHistory: `${wallet}/withdrawDepositeHistory`,
//   transationHistory: `${wallet}/transationHistory`,
//   statistic: `${wallet}/statistic`,
//   get_wallet_coinImageData: `${wallet}/coinImageData`,

// };
export default ApiConfig;
